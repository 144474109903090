import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { Input, LoadingSpinner } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { BUTTON_AS_TYPE, BUTTON_TYPE } from '@motorway/mw-highway-code/enums';

import cypressIds from 'CypressId';
import { postLoginEmail } from 'PublicAPI';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';

import { GA_TAGS, SIGN_IN_PAGE_EVENTS } from 'Utilities/analytics';
import { formatBrandNamePlural } from 'Utilities/formatter';
import {
  composeValidators,
  emailValidator,
  getFieldState,
  requiredValidator,
} from 'Utilities/formValidators';
import { applyCypressData } from 'Utilities/index';

import { ComponentContent, CONTENT_SIZES } from 'Layout';

import PageLoaderError from '../../../../components/transitions/PageLoaderError/PageLoaderError';
import LocalTexts from '../../../../texts.json';
import { EmailSent } from '../emailSent/EmailSent';
import { SignInFailed } from '../signInFailed/SignInFailed';

import { SignInSocial } from './SignInSocial';

import styles from './SignIn.scss';

const LocalT = new MDText(LocalTexts);

type SignInProps = {
  carValueTracker?: boolean;
  email?: string;
  numberOfCarsSold?: number;
  onTrackerEmailSuccess?: () => void;
  showSocialValidation?: boolean;
  subTitle?: string;
};

export const SignIn = ({
  carValueTracker,
  email: initialEmail,
  numberOfCarsSold,
  onTrackerEmailSuccess,
  showSocialValidation = false,
  subTitle,
}: SignInProps) => {
  const { vehicleLookupActions: { update }, vehicleLookupState } = useContext(VehicleLookupContext);
  const { userActions, userState: user } = useContext(UserContext);
  const [enableSocialValidation, setEnableSocialValidation] = useState(false);

  const userEmail = useRef<string>();

  const [emailSent, setEmailSent] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<Record<string, unknown> | null>(null);

  const location = useLocation();

  const isSignInRoute = location.pathname.includes('/sign-in');

  const existingVehicle = vehicleLookupState?.owned;

  const apiErrorMessage = 'No such seller.';

  const { display_name: displayName } = vehicleLookupState?.make ?? {};
  const brandName = formatBrandNamePlural(displayName ?? '');

  useEffect(() => {
    setEnableSocialValidation(true);
  }, []);

  useEffect(() => {
    GA_TAGS.SIGN_IN(isSignInRoute);
  }, [isSignInRoute]);

  useEffect(() => {
    SIGN_IN_PAGE_EVENTS.SIGN_IN_LOADED(carValueTracker ?? false);
  }, [carValueTracker]);

  useEffect(() => {
    if (apiError?.message === apiErrorMessage && carValueTracker) {
      update({ showTrackerSignUp: true });
    }
  }, [apiError, carValueTracker, update]);

  useEffect(() => {
    if (apiError) {
      GA_TAGS.SIGN_IN_NO_ACCOUNT(isSignInRoute);
    }

    if (emailSent) {
      GA_TAGS.SIGN_IN_SENT(isSignInRoute);
      onTrackerEmailSuccess?.();
    }

    if ((apiError && apiError?.message !== apiErrorMessage) || emailSent) {
      SIGN_IN_PAGE_EVENTS.SIGN_IN_CTA_CLICKED({ success: !!emailSent });
    }
  }, [apiError, emailSent, isSignInRoute, onTrackerEmailSuccess]);

  const submitUserEmail = async () => {
    const email = userEmail.current;

    try {
      const searchParams = new URLSearchParams(location.search);
      const redirect = searchParams.get('redirect');

      const buildParams = new URLSearchParams();
      if (vehicleLookupState?.enquiry?.mileage) {
        buildParams.set('mileage', vehicleLookupState.enquiry.mileage?.toString());
      }

      const vrm = vehicleLookupState?.vrm || '';

      const result = await postLoginEmail({ deeplink: redirect || `/${vrm}?${buildParams.toString()}`, email });

      if (result.message === 'Sent login email') {
        setEmailSent(email);
      }
    } catch (error: any) {
      if (error.message === apiErrorMessage) {
        if (existingVehicle && !isSignInRoute) {
          userActions.update({ email });
          update({ owned: false });
          GA_TAGS.SIGN_IN_NO_ACCOUNT_VRM();
        }
      } else {
        window?.Sentry?.captureException(new Error(error));
      }

      setApiError(error);
    }

    setLoading(false);
  };

  const onSubmit = async ({ email }: { email: string }) => {
    userEmail.current = email;
    setLoading(true);

    return submitUserEmail();
  };

  const goBack = () => {
    setApiError(null);
  };

  if (loading) {
    return (
      // @ts-expect-error empty className prop
      <ComponentContent maxWidth={CONTENT_SIZES.small}>
        <div className={styles.loading}>
          <LoadingSpinner />
        </div>
      </ComponentContent>
    );
  }

  if (emailSent) {
    return <EmailSent email={emailSent} />;
  }

  if (apiError) {
    if (apiError.message === apiErrorMessage && !carValueTracker) {
      return <SignInFailed onBackClick={goBack} />;
    }

    if (apiError.message !== apiErrorMessage) {
      return <PageLoaderError />;
    }
  }

  return (
    <div data-main-override className={styles.component}>
      {/* @ts-expect-error empty className prop */}
      <ComponentContent maxWidth={CONTENT_SIZES.big}>
        <h2>
          {isSignInRoute
            ? LocalT.translate('signInView.title')
            : LocalT.translate('warmSignInView.title')}
        </h2>
        {subTitle && (<p className={styles.subTitle}>{subTitle}</p>)}
      </ComponentContent>
      {/* @ts-expect-error empty className prop */}
      <ComponentContent maxWidth={CONTENT_SIZES.small}>
        <Form
          initialValues={{ email: initialEmail || user?.email }}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <fieldset>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  validate={composeValidators(
                    requiredValidator(
                      LocalT.translate('components.emailField.errorMessage.default'),
                    ),
                    emailValidator(
                      LocalT.translate('components.emailField.errorMessage.default'),
                    ),
                  )}
                >
                  {({ input, meta, ...props }) => (
                    <Input
                      feedbackTooltip={{
                        content: meta.error,
                        placementFlipped: 'top',
                        zIndex: 9,
                      }}
                      inputProps={{
                        ...props,
                        ...meta,
                        input: {
                          ...applyCypressData(cypressIds.fields.emailField),
                          ...input,
                          placeholder: LocalT.translate(
                            'components.emailField.placeholder',
                          ),
                        },
                      }}
                      intent={getFieldState(meta)}
                      label={LocalT.translate('components.emailField.label')}
                    />
                  )}
                </Field>
              </fieldset>
              <div className={styles.buttons}>
                <Button
                  fullWidth
                  as={BUTTON_AS_TYPE.BUTTON}
                  data-cy='signInButton'
                  icon='chevron'
                  label={isSignInRoute
                    ? LocalT.translate('signInView.buttonLabel')
                    : LocalT.translate('warmSignInView.buttonLabel')}
                  loading={submitting}
                  type={BUTTON_TYPE.SUBMIT}
                />
              </div>
            </form>
          )}
        />
        {
          enableSocialValidation && (
            <SignInSocial {...{ brandName, numberOfCarsSold, showSocialValidation, styles }} />
          )
        }
      </ComponentContent>
    </div>
  );
};
